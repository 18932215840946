import React from 'react';
import logo from './logo.svg';
import './App.css';
import { PopupButton } from '@typeform/embed-react'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

      </header>
      <div style={{display: "flex", flex: 1, flexDirection: "column",alignItems: "center", justifyContent: "space-between", height: "100%"}}>
        <div style={{
          padding: "8px",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "flex-start",
          maxWidth: "70%",
          margin: 20, fontFamily: "courier", color: "#dddddd", backgroundColor: "#000000", borderRadius: "8px"}}>
          <div style={{margin: 5, textAlign: "left"}}> <span>systems programming</span></div>
          <div style={{margin: 5, textAlign: "left"}}> <span>software engineering</span></div>
          <div style={{margin: 5, textAlign: "left"}}> <span>smart contract development</span></div>
        </div>
        <div style={{marginTop: 36}}>
          <PopupButton style={{paddingLeft: 20, paddingRight: 20, border: "none", fontWeight: "bolder", borderRadius: "5px", backgroundColor: "#000000", color: "#ffffff", fontSize: 24, fontFamily: "Helvetica"}} id="Fiy2RuJ7"  className="my-button">
            LEARN MORE
          </PopupButton>
        </div>
      </div>
    </div>
  );
}

export default App;
